<template>
  <b-container fluid>
    <b-row class="text-center mb-4" align-h="center" v-if="!permissions.read">
      <b-col>
        <h3>You do not have permissions for this page</h3>
      </b-col>
    </b-row>
    <b-row class="text-center mb-4" align-h="center" v-if="loading && permissions.read">
      <b-col>
        <b-container class="text-center">
          <h1>Loading data</h1>
          <h3>... loaded: {{rows.length}} ...</h3>
          <h1><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></h1>
        </b-container>
      </b-col>
    </b-row>
    <filter-interface
      v-if="!loading && permissions.read"
      :dateFilter="dateFilter"
      :model="model"
      :nav="nav"
      :pageName="pageName"
      :rows="rows"
      :searchProperties="searchProperties"
      :staticDataProperties="staticDataProperties"
      :staticData="staticData"
      :loading="loading"
      :permissions="permissions"
      :referenceData="referenceData"
      :sortDirectionProp="sortDirection"
      :sortPropertyProp="sortProperty"
      :sortPropertyOptionsProp="sortPropertyOptions"
    />
  </b-container>
</template>

<script>
import _ from 'lodash'
import ac from '@/libs/accesscontrol'
import FilterInterface from '@/components/FilterInterface.vue'

export default {
  name: 'ReportingMi',
  components: {
    FilterInterface
  },
  computed: {
    rows: {
      get () {
        return this.$store.state.reportingmi
      },
      set (payload) {
        this.$store.commit('setReportingmi', payload)
      }
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    this.permissions.read = ac.can(this.user.acgroups).readAny('impactreportingmi').granted
    this.permissions.add = false
    if (this.permissions.read) {
      this.load()
    }
  },
  data () {
    return {
      dateFilter: {
        property: 'created_at'
      },
      loading: true,
      model: 'reportingmi',
      nav: [
        { name: 'Reportingmi', text: 'Reporting' },
        { name: 'ReportingmiEmails', text: 'Emails' },
        { name: 'ReportingmiDataStrats', text: 'Data stratification' }
      ],
      pageName: 'Reporting MI',
      permissions: {
        add: false,
        read: false
      },
      referenceData: null,
      searchProperties: ['function', 'reason', 'reasonother', 'sector', 'industrygroup', 'industry', 'subindustry', 'created_by'],
      staticDataProperties: [
        { name: 'Function', property: 'function', direct: 'function' },
        { name: 'Reason', property: 'reason', direct: 'reason' },
        { name: 'Sector', property: 'sector', direct: 'sector' },
        { name: 'Industry group', property: 'industrygroup', direct: 'industrygroup' },
        { name: 'Industry', property: 'industry', direct: 'industry' },
        { name: 'Sub-industry', property: 'subindustry', direct: 'subindustry' }
      ],
      sortDirection: 'desc',
      sortProperty: 'created_at',
      sortPropertyOptions: [
        { value: 'created_at', text: 'date created' },
        { value: 'created_by', text: 'user' },
        { value: 'size', text: 'opportunity size' }
      ],
      staticData: null
    }
  },
  methods: {
    load: async function () {
      try {
        this.loading = true
        await this.loadRows()
        const staticData = {}
        _.each(['function', 'reason', 'sector', 'industrygroup', 'industry', 'subindustry', 'created_by'], p => {
          let set = []
          _.each(this.rows, row => {
            set.push({ id: row[p], name: row[p] })
          })
          set = _.uniqBy(set, 'id')
          set = _.sortBy(set, ['name'])
          staticData[p] = set
        })
        this.staticData = staticData
        this.loading = false
      } catch (e) {
        this.$logger.warn('saved ERROR: ', e)
      }
    },
    loadRows: async function () {
      this.loading = true
      this.rows = []
      try {
        const limit = 100
        let offset = 0
        let response = []
        let page = 0
        do {
          offset = page * limit
          response = await this.$Amplify.API.get('cosmos', `/impact/reportingmi/limit/${limit}/offset/${offset}`)
          const data = _.concat(this.rows, response)
          this.rows = data
          page++
          await this.$nextTick()
        } while (response.length === limit)
      } catch (e) {
        this.$logger.warn('saved ERROR: ', e)
      }
    }
  }
}
</script>

<style>
</style>
